/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import ImageMeta from "../components/ImageMeta"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import ButtonGroupMap from '../components/Button/ButtonGroupMap'
import TextPhoto from "../components/TextPhoto/TextPhoto"
import GetStarted from "../components/CTA/GetStarted"
import OffsetHeadingCTA from "../components/CTA/OffsetHeadingCTA"
import SEO from "../components/seo"

import MarkdownViewer from '@bit/azheng.joshua-tree.markdown-viewer';

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

export default ({ data, location }) => {

  const post = data.allUniquePagesJson.nodes[0];

  let language = "en";


  return (
    <SharedStateProvider>
      <Layout pageTitle="first-visit">
        <SEO title={post.metaTitle} description={post.metaDescription} pathname={location.pathname} schemaPage={post.schemaPage ? post.schemaPage : null} />
        <section className="joshua-tree-content your-first-visit-top top-section section color-back text-section is-mobile">
          <div className="columns has-text-centered">
            <div className="column">
              <h1>{post.heading}</h1>
            </div>
          </div>

          <div className="columns">
            <div className="column is-3"></div>
            <div className="column" dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(post.topBlurb)
            )}>
            </div>
            <div className="column is-3"></div>
          </div>
        </section>

        <div className="joshua-tree-content your-first-visit">

          {!post.hasImages
            ? <TextOnly post={post} />
            : <>
                <section className="body-section">
                  <TextPhoto
                    columnsClasses="is-vcentered reverse-columns"
                    topDown={false}
                    sideColumnIs={3}
                    centerColumnIs={1}
                    leftColumn={
                      <div className="column">
                        <h2 className="">{post.prepareFirstAppointment.heading}</h2>
                        <div dangerouslySetInnerHTML={createHtml(
                          converter.makeHtml(post.prepareFirstAppointment.text)
                        )}>
                        </div>
                        {post.prepareFirstAppointment.buttons && <ButtonGroupMap tight  noReverse buttons={post.prepareFirstAppointment.buttons} />}
                      </div>
                    }
                    rightColumn={
                      <div className="column">
                        <ImageMeta
                          cloudName="nuvolum"
                          className="is-fullwidth-mobile"
                          publicId={post.prepareFirstAppointment.imageId}
                          responsive
                          width="auto"
                        >

                        </ImageMeta>
                      </div>
                    }
                  />
                </section>
                <section className="body-section">
                  <TextPhoto
                    columnsClasses="is-vcentered"
                    sideColumnIs={2}
                    centerColumnIs={1}
                    leftColumn={
                      <div className="column is-10">
                        <ImageMeta
                          cloudName="nuvolum"
                          className="is-fullwidth-mobile"
                          publicId={post.itemsToBring.imageId}

                          width="auto"
                        >

                        </ImageMeta>
                      </div>
                    }
                    rightColumn={
                      <div className="column is-9 right-column-under">
                        <h3 className="items-to-bring">{post.itemsToBring.heading}</h3>
                        <div dangerouslySetInnerHTML={createHtml(
                          converter.makeHtml(post.itemsToBring.text)
                        )}>
                        </div>
                      </div>
                    }
                  />
                </section>

                <section className="body-section what-to-expect" style={{ paddingTop: "0" }}>
                  <div className="columns">
                    <div className="column is-3"></div>
                    <div className="column">
                      <h3>{post.whatToExpect.heading}</h3>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-3"></div>
                    <div className="column">
                      <div dangerouslySetInnerHTML={createHtml(
                        converter.makeHtml(post.whatToExpect.text)
                      )}>
                      </div>
                      {post.whatToExpect.buttons && <ButtonGroupMap tight  noReverse buttons={post.whatToExpect.buttons} />}
                    </div>
                    <div className="column is-3"></div>
                  </div>
                </section>
              </>
            }

          {/* <div className="columns">
            <div className="column is-offset-2 is-20">
              <div className="hl"></div>
            </div>
          </div> */}

          <section className="body-section color-back financial-section">
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column">
                <h3 style={{ marginBottom: "1.5rem", marginTop: "0" }}>{post.financialInsurancePolicy.heading}</h3>
              </div>
            </div>
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column" dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(post.financialInsurancePolicy.text)
              )}>
              </div>
              <div className="column is-3"></div>
            </div>
          </section>

          <section className="body-section last">
            <GetStarted
              headingSideColumnIs={4}
              sideColumnIs={4}
              centerHeading
              centerText
              heading={
                language === "es"
                  ? getStartedDataEs.heading
                  : post.getStarted.heading
              }
              paragraph={
                <MarkdownViewer markdown={language === "es"
                  ? getStartedDataEs.blurb
                  : post.getStarted.blurb} />
              }
              buttonText={
                language === "es"
                  ? getStartedDataEs.buttonText
                  : post.getStarted.buttonText
              }
              buttonUrl={
                language === "es" ? getStartedDataEs.href : post.getStarted.href
              }
              buttons={post.getStarted.buttons}
            />
          </section>


        </div>
      </Layout>
    </SharedStateProvider>
  )
}

function TextOnly ({ post }) {
 return (
   <div className="text-only">
    <section className="body-section">
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
          <h2 className="h3-style">{post.prepareFirstAppointment.heading}</h2>
          <div dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(post.prepareFirstAppointment.text)
          )}>
          </div>
          {post.prepareFirstAppointment.buttons && <ButtonGroupMap tight  noReverse buttons={post.prepareFirstAppointment.buttons} />}
        </div>
        <div className="column is-3"></div>
      </div>
    </section>

    <section className="body-section items-to-bring color-back">
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
          <h3 className="items-to-bring">{post.itemsToBring.heading}</h3>
          <div dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(post.itemsToBring.text)
          )}>
          </div>
        </div>
        <div className="column is-3"></div>
      </div>
    </section>

    <section className="body-section items-to-bring">
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
          <h3 className="items-to-bring">Parents of Minors</h3>
          <p>Patients under the age of 18 must be accompanied to the consultation appointment by a parent or legal guardian.</p>
        </div>
        <div className="column is-3"></div>
      </div>
    </section>

    <section className="body-section items-to-bring color-back">
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
        <h3>What to Expect</h3>
        <p>During your first visit, Dr. Hudson will perform an examination and take 3D scans of your mouth. He will design a treatment plan based on your specific needs and answer any questions you may have. He will also discuss your options for anesthesia prior to undergoing any surgical procedure to keep you comfortable and at ease. Our team will also provide you with a cost estimate and discuss your options for payment.</p>
        </div>
        <div className="column is-3"></div>
      </div>
    </section>

    <section className="body-section what-to-expect">
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
        <h3>{post.whatToExpect.heading}</h3>
        <div dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(post.whatToExpect.text)
          )}>
          </div>
          {post.whatToExpect.buttons && <ButtonGroupMap tight  noReverse buttons={post.whatToExpect.buttons} />}
        </div>
        <div className="column is-3"></div>
      </div>
    </section>
  </div>
 )
}

export const pageQuery = graphql`
query yourFirstVisitPage ($title: String!)  {
  allUniquePagesJson(filter: {title: {eq: $title}}) {
    nodes {
      heading
      topBlurb
      metaTitle
      metaDescription
      hasImages
      schemaPage
      title
      financialInsurancePolicy {
        heading
        text
      }
      prepareFirstAppointment {
        imageId
        text
        heading
        buttons {
          button {
            buttonText
            href
            destination
            appearance
          }
        }
      }
      itemsToBring {
        heading
        imageId
        text
      }
      whatToExpect {
        heading
        text
        buttons {
          button {
            href
            buttonText
            destination
            appearance
          }
        }
      }
      getStarted {
        blurb
        buttonText
        heading
        href
        subheading
        buttons {
          button {
            buttonText
            href
            destination
            appearance
          }
        }
      }
    }
  }
}

`
